import { BellOutlined, DotChartOutlined, EyeOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { formatConcept } from "../../../../utils/Numbers";
import { Spin } from "antd";
import { IInvestorsTableProps } from "../IInvestmentsDetails";

const InvestorsTable = (props: IInvestorsTableProps) => {
  const { 
    translations, 
    selectedMode, 
    toggleRow, 
    investorVehicleData, 
    investorFundData,
    loading, 
    handleChartSelection,
    viewReport,
    documentLoading,
    selectedVehicleRadio,
    vehiclesSelectedChart,
    handleSelectedVehicleRadio,
    mainColor
  } = props;

  const today = dayjs().format('DD/MM/YYYY');
  return (
    <Spin
      size="large"
      spinning={loading.table || documentLoading}
      indicator={<LoadingOutlined style={{ fontSize: 46, color: mainColor, marginTop: '60px' }} />}
      tip={documentLoading ? translations.generandoDocumento : ''}
      >
    <div className="investor-table-container">
      {selectedMode === "movementDetails" && (
        <> 
          <table className="investor-table" id="investor-table">
              <colgroup>
                  <col style={{width: "18%"}} />
                  <col style={{width: "10%"}} />
                  <col style={{width: "12%"}} />
                  <col style={{width: "12%"}} />
                  <col style={{width: "12%"}} />
                  <col style={{width: "11%"}} />
                  <col style={{width: "11%"}} />
                  <col style={{width: "10%"}} />
                  <col style={{width: "4%"}} />
              </colgroup>
              <thead>
                  <tr>
                  	  <th>{translations.informationToday} {today}</th>
                      <th>{translations.fecha}</th>
                      <th>{translations.compromiso}</th>
                      <th>{translations.desembolsos}</th>
                      <th>{translations.distribuciones}</th>
                      <th>{translations.shares}</th>
                      <th>{translations.valorLiquidativo}</th>
                      <th>{translations.docs}</th>
                      <th>{translations.grafico}</th>
                  </tr>
              </thead>
              <tbody>
                {investorVehicleData.map((vehicle: any, index: number) => {
                  return (
                    <>
                      <tr className="investor-table-vehicle-row" key={index}>
                        <td onClick={()=> toggleRow(index)}>{(vehicle.movements && vehicle.movements.length > 0) && <PlusCircleOutlined />} 
                          <span>{vehicle.vehicle}</span>
                        </td>
                        <td onClick={()=> toggleRow(index)}></td>
                        <td onClick={()=> toggleRow(index)}>€{formatConcept(vehicle.commitment)}</td>
                        <td onClick={()=> toggleRow(index)}>€{formatConcept(vehicle.capitalCalls)}</td>
                        <td onClick={()=> toggleRow(index)}>€{formatConcept(vehicle.distributions)}</td>
                        <td onClick={()=> toggleRow(index)}>{formatConcept(vehicle.numShares, 4)}</td>
                        <td onClick={()=> toggleRow(index)}></td>
                        <td ></td>
                        <td>{vehicle.idVehicle && 
                          <input type="checkbox" 
                            checked={vehiclesSelectedChart.includes(vehicle.idVehicle)} 
                            onChange={(e)=> handleChartSelection(e.target.checked, vehicle.idVehicle)}/>}
                        </td>
                      </tr>
                      {vehicle.movements?.sort((a: any, b: any) => (dayjs(a.date).toDate() as any) - (dayjs(b.date).toDate() as any)).map((movement: any, movementIndex: number) => {
                        return (
                          <tr className={`investor-table-item-row hidden-row ${`row-${index.toString()}`}`} id={index.toString()} key={movementIndex}>
                            <td>{translations[movement.type]} {movement.paid === false && <span className="danger" ><BellOutlined/></span>}</td>
                            <td>{dayjs(movement.date).format('DD/MM/YYYY')}</td>
                            <td>{movement.commitment ? '€' : ''} {formatConcept(movement.commitment)}</td>
                            <td>{movement.amount ? '€' : ''}{formatConcept(movement.amount + movement.capitalIncrease + movement.shareholderContribution + movement.sharePremium)}</td>
                            <td>{movement.buyback ? '€' : ''}{formatConcept(movement.buyback)}</td>
                            <td>{formatConcept(movement.shares, 4)}</td>
                            <td>{formatConcept(movement.sharesValue, 4)}</td>
                            <td>
                              <button onClick={()=> viewReport(movement.type, dayjs(movement.date).format('YYYY-MM-DD'), [movement.idInvestor])}
                                className="view-documents-btn"><EyeOutlined />
                              </button>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
              </tbody>
          </table>
        </>
      )}

      {selectedMode === "positionValue" && ( 
        <table className="investor-table" id="investor-table">
          <colgroup>
              <col style={{width: "22%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "12%"}} />
              <col style={{width: "6%"}} />
          </colgroup>
          <thead>
              <tr>
                  <th>{translations.informationToday} {today}</th>
                  <th>{translations.compromiso}</th>
                  <th>{translations.desembolsos}</th>
                  <th>{translations.distribuciones}</th>
                  <th>{translations.shares}</th>
                  <th>{translations.valorLiquidativoProforma} *</th>
                  <th>{translations.valorPosicion}</th>
                  <th>{translations.grafico}</th>
              </tr>
          </thead>
          <tbody>
            {investorFundData.map((fund: any, index: number) => {
              return (
                <>
                  <tr className="investor-table-vehicle-row" key={index}>
                    <td onClick={()=> toggleRow(index)}> 
                      <span>{fund.vehicleName}</span>
                    </td>
                    <td>€{formatConcept(fund.commitment)}</td>
                    <td>€{formatConcept(fund.capitalCalls)}</td>
                    <td>€{formatConcept(fund.distributions)}</td>
                    <td>{formatConcept(fund.numShares, 4)}</td>
                    <td>€{formatConcept(fund.netAssetValue, 4)}</td>
                    <td>€{formatConcept(fund.positionValue)}</td>
                    <td>{
                      fund.vehicleName !== 'Total' &&
                        <input type='radio'
                            checked={selectedVehicleRadio === fund.idVehicle}
                            onChange={(_e: any) => handleSelectedVehicleRadio(fund.idVehicle ?? 0)}
                        />
                      }
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
    </Spin>

  );
};

export default InvestorsTable;
